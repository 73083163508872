import * as React from 'react';
import Header from '../layouts/header';
import Footer from '../layouts/footer';
import Typing from 'react-typing-animation';
import '../styles/_bootstrap.scss'
import trevorImage from '../assets/headshots/trevor.jpg';
import johnImage from '../assets/headshots/john.jpg';
import colinImage from '../assets/headshots/colin.jpg';
import bexImage from '../assets/headshots/bex.jpg';
import lorraineImage from '../assets/headshots/lorraine.jpg';
import harryImage from '../assets/headshots/harry.jpg';

class About extends React.PureComponent<{}, {}> {
    public render() {
        return (
            <>
            <Header />
            <div className="page">
                <div className="container-fluid hero">
                    <section className="section about-us hero-big background-gradient">
                        <div className="hero-no-image padding-top-XL padding-bottom-M container">
                            <div className="col-xs-12 col-sm-8 col-sm-offset-2 padding-none">
                                <h1 className="padding-bottom-M">About DirekTek</h1>
                                <p className="text-XL padding-bottom-M">DirekTek is a channel platform connecting leading brands with retailers and their customers worldwide. We power exceptional retail experiences for forward-thinking businesses.</p>
                            </div>
                        </div>
                        <div className="hidden-sm hidden-xs hero-with-image text-center" style={{
                            backgroundImage:  "url(" + "https://res.cloudinary.com/dbt0ljnls/image/upload/q_100/v1489760609/download_gkz0qw.svg" + ")", 
                            backgroundSize: "contain", 
                            paddingTop: "20rem",
                            paddingBottom:"20rem",
                            backgroundPosition:"center",
                            backgroundRepeat:"no-repeat",
                            transform: "matrix(1, .05, -.01, 1, -1.5, -1)"}}>
                            <Typing>
                                <h2 className="text-body platform-services">Dropship as a Service</h2>
                            </Typing>
                        </div>
                    </section>
                </div>
                <div className="container-fluid padding-none body">
                    <section className="section about-us">
                        <div className="container padding-none">
                            <div className="col-xs-12 col-sm-8 col-sm-offset-2">
                                <h2 className="padding-bottom-M">What we do</h2>
                                <p className="text-L">
                                  We bring together expertise in product curation, forecasting, engineering, support and more, enabling retailers to deliver great products to their customers with our turnkey solutions.<br/>
                                </p>
                            </div>
                        </div>
                    </section>
                    <section className="section about-us background-grey">
                        <div className="container padding-none">
                            <div className="col-xs-12 col-sm-8 col-sm-offset-2">
                                <h2 className="padding-bottom-M">Who we are</h2>
                                <p className="text-M">Building critical supply chain infrastructure is hard. Our team of industry experts care deeply about our customers and feel passionate about building the future of retail commerce.</p>
                            </div>
                            <div className="hidden-xs col-sm-10 col-sm-offset-1 text-center">
                                <h3 className="text-center padding-bottom">Leadership</h3>
                                <div className="team">
                                    <input type="checkbox" className="show-more-state" id="more-team"/>
                                        <ul className="text-center inline-list show-more-wrap margin-none">
                                            <li className="col-md-3 hidden-xs hidden-sm"></li>
                                            <li className="col-xs-4 col-md-2"><img className="portrait img-responsive img-circle center-block" src={trevorImage}/><h5>Trevor Marshall</h5><p>Managing Director</p></li>
                                            <li className="col-xs-4 col-md-2"><img className="portrait img-responsive img-circle center-block" src={johnImage}/><h5>John Strode</h5><p>Operations Director</p></li>
                                            <li className="col-xs-4 col-md-2"><img className="portrait img-responsive img-circle center-block" src={colinImage}/><h5>Colin Drake</h5><p>Financial Director</p></li>
                                            <div className="row hidden-sm hidden-xs"></div>
                                            <li className="col-md-3 hidden-xs hidden-sm"></li>
                                            <li className="col-xs-4 col-md-2"><img className="portrait img-responsive img-circle center-block" src={lorraineImage}/><h5>Lorraine Persaud</h5><p>Purchasing Director</p></li>
                                            <li className="col-xs-4 col-md-2"><img className="portrait img-responsive img-circle center-block" src={harryImage}/><h5>Harry Manic</h5><p>CGE Sales Director</p></li>
                                            <li className="col-xs-4 col-md-2"><img className="portrait img-responsive img-circle center-block" src={bexImage}/><h5>Rebecca Marshall</h5><p>Sales &amp; Marketing Director</p></li>
                                        </ul>
                                    {/* <label htmlFor="more-team" className="read-more-trigger squared" style={{background:"#fff"}}></label> */}
                                </div>
                            </div>
                        </div> 
                    </section>
                    <section className="section about-us">
                        <div className="container">
                            <h2 className="padding-bottom-M">Where we are</h2>
                            <div className="col-xs-12 col-sm-3 padding-none">
                                <div className="hq-box text-left">
                                    <p className="title">HQ Address</p>
                                    <p className="address-line">Unit 5 Century Point, Halifax Road</p>
                                    <p className="address-line">High Wycombe, Buckinghamshire</p>
                                    <p className="address-line">HP12 3SL</p>
                                    <a href="https://www.google.co.uk/maps/dir/&apos;&apos;/direktek+distribution/@51.6200019,-0.8511946,12z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x48768a6af7690c13:0x22844e169213412b!2m2!1d-0.7811551!2d51.6200227" target="_blank">Get directions</a>
                                </div>
                            </div>
                            <div className="hidden-xs hidden-sm col-sm-9">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2477.173947210387!2d-0.7833437838918369!3d51.62002267965391!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48768a6af7690c13%3A0x22844e169213412b!2sDirektek+Distribution+Ltd!5e0!3m2!1sen!2suk!4v1497280140131" width="100%" height="400" frameBorder="0" style= {{border:0, allowFullScreen:"true"}} ></iframe>
                            </div>
                        </div>
                    </section>
                    <section className="background-grey border-bottom text-left">
                        <div className="container section about-us">
                            <div className="cta-box-light">
                                <div className="col-md-8 col-sm-12">
                                    <h2 className="text-green">Fancy a challenge?</h2>
                                    <p className="text-L">Help us empower global brands and retailers everywhere</p>
                                </div>
                                <div className="col-md-4 col-sm-12 margin-top-L">
                                    <a className="btn btn-success squared text-L" href="jobs.html#open-positions">Join the team</a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <Footer/>
            </>
        );
    }
}

export default About;